.button, .small_button {
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 0.2em 0.5em;
  color: #fff;
  background: #a28522;
  font-size: 2.4rem;
  cursor: pointer;
}

.small_button {
  font-size: 1.8rem;
}

.add_button {
  display: inline-block;
  background: #31333C;
  line-height: 1.4em;
  text-decoration: none;
  color: #fff;
  background-image: url("plus.png");
  background-repeat: no-repeat;
  background-position: 8px center;
  padding: 0 15px 0 30px;
  border-radius: 12px;
}

body {
  font-family: Helvetica, Arial, Verdana, sans-serif;
  font-size: 12pt;
  color: #333333;
  height: auto;
}

h1, h2, h3, h4, h5, h6 {
  color: #a28522;
}

p.download-agb,
header nav,
#show-menu, .show-menu,
.pswp {
  display: none;
}
